.map-container {
    height: 200px;
    margin-bottom: 30px;
    border-radius: 25px;
}
.map-container--preview {
    height: 200px;
    border-radius: 25px;
    margin-bottom: 30px;
}
.map-container--firstchild {
    margin-top: 0px;
}

@media (min-width: 576px) {
    .map-container {
        height: 400px;
        border-radius: 25px;
        margin-bottom: 16px;
    }
}
