.cupons__header {
    padding: 16px 32px;
    display: flex;
    justify-content: space-between;
}

.cupons__header--titles {
  font-weight: 600;
  color: var(--main-green);

}
#Search::-webkit-search-cancel-button{
  position:relative;
  right:10px;
}

.search-bar-button{
  background-color: var(--main-green);
  color: white;
  margin-left: 10px;
}

.users__container {
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px; 
  }
  .search-bar__container{
    text-align: center;
  }
  .search-bar {
    background-color: var(--main-white);
    color: var(--main-black);
    font-weight: 500;
    border-radius: 16px;
    border: 3px solid var(--main-green);
    margin: auto;
    padding-left: 3%;
    width: 50%;
    outline: none;
    box-shadow: none;
}

.search-bar::placeholder {
    color: var(--grey-2);
    font-weight: 400;
    padding-left: 3%;
    font-size: 14px;
}

.search-bar:focus {
    outline: none;
    box-shadow: var(--main-black);
    background-color: white;
}

.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-left:0px;
}

@media (min-width: 992px) {
  .search-bar {
    width: 30%;
  }
}