.users-list__container {
  width: 90%;
  margin-left: 5% ;
  margin-top: 20px;
  margin-bottom: 60px;
  overflow-x:auto;
}

.admin-users-list__container-table{
  width: 100%;
}

.admin-users-list__container-table th{
  background-color: #876cd3;
  color: white;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.admin-users-list__firstchild{
  border-top-left-radius: 20px;
}

.admin-users-list__lastchild{
  border-top-right-radius: 20px;
}

.admin-users-list__name{
  font-weight: 600;
}

.user-list__select-container{
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

.user-list__save-button{
  margin-left: 10px;
  background-color: white;
  color: var(--main-green);
}

.user-list__select-container .generic-form__error-placeholder, .user-list__select-container label{
  display: none;
}

.user-list__select-container .generic-form__form-input{
  background-color: #e0dfed;
  color: #686579;
  border-radius: 30px;
  padding-right: 5px;
}

.admin-users-list__container-table td{
  border-bottom: 1px solid rgb(117, 117, 117);
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
}

.admin-users-list__container-table label{
  margin-right: 5px;
}