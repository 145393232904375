.auth-modal__header {
    color: var(--main-green);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0px;
    font-weight: 600;
    font-size: 20px;
}

.auth-modal__close {
    position: absolute;
    background: transparent;
    right: 12px;
    top: 0px;
    color: var(--main-green);
    font-size: 24px;
}

.auth-form {
    text-align: center;
    vertical-align: middle;
    padding: 16px;
}


.expo-auth-form__input-block--grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2%;
    width: 100%;
}

.auth-form__input-block--column {
    width: 100%;
    text-align: justify;
}

.auth-form__form-tag {
    display: flex;
    margin-left: 10%;
    margin-top: 12px;
}

.auth-form__form-input {
    border: 1px solid #876cd3;
    border-radius: 50px;
    padding: 5px 15px;
    font-weight: 100;
    color: #6c677b !important;
    text-align: initial;
    background-color: white !important;
    width: 80%;
}

.auth-form__form-input--inline {
    width: 100%;
}

.auth-form__form-input::placeholder {
    color: var(--grey-2);
}

input:focus.auth-form__form-input {
    outline-color: var(--main-orange);
}

.auth-form__error-placeholder {
    min-height: 25px;
    margin-left: 10%;
    display: flex;
    align-items: center;
}

.auth-form__api-error-placeholder {
    border: 1px solid var(--main-orange);
    background-color: #fcc6bd;
    margin: 0px 10%;
    padding: 8px 0px;
}

.auth-form__error-placeholder--column {
    margin-left: 0;
}

.auth-form__input-label {
    margin-bottom: 0px;
    margin-right: 12px;
}

.auth-form__validation-error {
    font-weight: 400;
    color: var(--main-red);
    text-align: initial;
}

.auth-form__api-error {
    font-weight: 600;
    color: var(--main-red);
}

.auth-form__link {
    padding-left: 0px;
    font-weight: 600;
    color: var(--main-green);
    background: transparent;
}

.auth-form__footer {
    display: block;
    margin-top: 24px;
    border-top: 1px solid var(--grey-3);
    justify-content: center;
    font-weight: 600;
    padding: 16px 0px 0px;
}

@media (min-width: 768px) {
    .auth-modal .modal-dialog {
        max-width: 500px;
    }
    .expo-auth-form__input-block--grid {
        grid-template-columns: 1fr 1fr;
    }
}
