.lottery-update-modal__header {
    color: var(--main-green);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0px;
    font-weight: 600;
    font-size: 20px;
}

.lottery-update-modal__close {
    position: absolute;
    background: transparent;
    right: 12px;
    top: 0px;
    color: var(--main-green);
    font-size: 24px;
}

.lottery-update-form {
    text-align: center;
    vertical-align: middle;
    padding: 16px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.lottery-update-form__avatar-container {
    margin-left: 10%;
    align-items: center;
}

.lottery-update-form__avatar-buttons-container {
    font-weight: 600;
    font-size: 14px;
    text-align: initial;
}

.lottery-update-form__input-block--grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2%;
    margin-left: 10%;
    width: 80%;
    margin-bottom: 0%;
}

.lottery-update-form__input-block--column {
    width: 100%;
    text-align: justify;
    margin-bottom: 0%;
}


.lottery-update-form__form-tag {
    display: flex;
    margin-left: 10%;
    margin-top: 12px;
}

.lottery-update-form__form-input {
    border: 1px solid #876cd3;
    border-radius: 50px;
    padding: 5px 15px;
    font-weight: 100;
    color: #6c677b;
    text-align: initial;
    width: 100%;
    background-color: white;
    margin-bottom: 10px;
}

.lottery-update-form__form-input--inline {
    width: 100%;
}

.lottery-update-form__form-input::placeholder {
    color: var(--grey-2);
}

input:focus.lottery-update-form__form-input {
    outline-color: var(--main-orange);
}

.lottery-update-form__error-placeholder {
    margin-left: 10%;
    display: flex;
    align-items: center;
}

.lottery-update-form__api-error-placeholder {
    border: 1px solid var(--main-orange);
    background-color: #fcc6bd;
    margin: 8px 10%;
    padding: 8px 0px;
}

.lottery-update-form__error-placeholder--column {
    margin-left: 0;
}

.lottery-update-form__input-label {
    text-align: left;
    color: #bbb9c4;
    font-size: 14px;
    margin-bottom: 10px;
}

.lottery-update-form__email {
    margin-left: 10%;
    margin-bottom: 2%;
    font-weight: 600;
    color: var(--main-black);
    text-align: initial;
}

.lottery-update-form__validation-error {
    font-weight: 400;
    color: var(--main-red);
    text-align: initial;
}

.lottery-update-form__api-error {
    font-weight: 600;
    color: var(--main-red);
}

.lottery-update-form__link {
    margin-top: 12px;
    padding-left: 0px;
    font-weight: 600;
    color: var(--main-orange);
    background: transparent;
}

.lottery-update-form__footer {
    display: block;
    margin-top: 24px;
    border-top: 1px solid var(--grey-3);
    justify-content: center;
    font-weight: 600;
    padding: 16px 0px 0px;
}

@media (min-width: 576px) {
    .lottery-update-form__avatar-container {
        display: flex;
    }
}


@media (min-width: 768px) {
    .lottery-update-modal .modal-dialog {
        max-width: 75%;
    }
    .lottery-update-form__input-block--grid {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 0%;
    }
}
