.footer {
    margin-top: auto;
    width: 100%;
	color: var(--main-black);
	align-items: center;
	justify-content: center;
	font-weight: 700;
}

.footer-block {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding: 16px 0px;
}

.footer__disclaimer-text{
    text-align: center;
    line-height: 20px;
}


.footer--right-margin {
    margin-right: 24px;
}

.footer__logo {
    line-height: 1;
    padding: 16px 60px 0px;
}


.footer-content__nav-link {
    text-decoration: none;
    color: var(--grey-1);
    font-size: 14px;
    margin-bottom: 8px;
    transition: ease 0.3s;
}

.footer-content__nav-link:hover {
    text-decoration: none;
    color: currentColor;
}

.footer-content__nav-link--block {
    display: block;
}

.footer-content__nav-link--small {
    font-size: 12px;
}

.footer__networks {
    font-size: 28px;
    text-decoration: none;
    color: var(--grey-1);
    font-weight: 700;
}

.footer__country-dropdown {
    border: 2px solid var(--grey-3);
    width: 164px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding: .5rem 1rem;
}

.footer__country-dropdown--styling {
    background-color: white;
    color: var(--main-black);
    font-weight: 600;
    font-size: 14px;
}

button.footer__country-dropdown--styling.btn-primary.dropdown-toggle,
button.footer__country-dropdown--styling.btn-primary:hover:enabled:focus {
    background: white;
    color: inherit;
    border: 2px solid var(--grey-3);
}

.footer__country-dropdown--styling.dropdown-item:focus {
    background: inherit;
    color: inherit;
}

.footer__country-dropdown--title {
    padding-right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.footer__country-dropdown::after {
    content: '';
    position: absolute;
    right: 16px;
    top: 12px;
    display: block;
    border-left: 2px solid var(--main-green);
    border-right: none;
    border-top: 2px solid var(--main-green);
    width: 12px;
    height: 12px;
    transform: rotate(225deg); 
}

.footer__disclaimer {
    background: var(--main-green);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-white);
    font-weight: 600;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}
.footerpoweredby-img{
    width: 90px;
    height: auto;
    margin-left: 10px;
}
.footerpoweredby-txt{
    text-decoration: none;
    justify-content: center;
    align-items: center;
    color: var(--main-white);
    background-color: transparent;
    font-weight: 600;
    margin-left: 20px;
}
.footerpoweredby-txt:hover {
    text-decoration: none;
    color: currentColor;
}

@media (min-width: 0px) and (max-width: 575px) {
	
    .footer--mobile-margin {
        margin: 12px 0px;
    }
}


@media (min-width: 768px) {
	.footer {
		justify-content: space-around;
	}

}
