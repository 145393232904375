.user-update-modal__header {
    color: var(--main-green);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0px;
    font-weight: 600;
    font-size: 20px;
}

.user-update-modal__close {
    position: absolute;
    background: transparent;
    right: 12px;
    top: 0px;
    color: var(--main-green);
    font-size: 24px;
}

.user-update-form__content{
    display: inline-flex;
}

.user-update-form {
    text-align: center;
    vertical-align: middle;
    padding: 16px;
}

.user-update-form__avatar-container {
    margin-left: 10%;
    align-items: center;
    display: grid;
    grid-auto-columns: 1fr;
    background-color: #eeedf3;
    margin: 40px;
    padding: 20px;
    border-radius: 30px;
    margin-bottom: 15px;
}

.user-update__caption{
    text-align: left;
    color: #bbb9c4;
    font-size: 14px;
    margin-bottom: 10px;
}

.user-update__caption__violet{
    color: #876cd3;
    font-weight: 800;
}

.user-update__caption-invisible{
    text-align: left;
    color: #bbb9c4;
    font-size: 14px;
    margin-bottom: 10px;
    visibility: hidden;
}

.user-update-form__avatar-buttons-container {
    font-weight: 600;
    font-size: 14px;
    text-align: initial;
    margin-left: auto;
    margin-right: auto;
}

.user-update-form__avatar-buttons-container button{
    width: auto;
    margin-right: 10px;
    margin-top: 20px;
}

.user-update-form__input-block--grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2%;
    width: 100%;
    margin-bottom: 2%;
}

.user-update-form__input-block--column {
    width: 100%;
    text-align: justify;
    margin-bottom: 2%;
}


.user-update-form__form-tag {
    display: flex;
    margin-left: 10%;
    margin-top: 12px;
}

.user-update-form__form-input {
    width: 80%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid var(--grey-3);
}

.user-update-form__form-input--inline {
    width: 100%;
}

.user-update-form__form-input::placeholder {
    color: var(--grey-2);
}

input:focus.user-update-form__form-input {
    outline-color: var(--main-orange);
}

.user-update-form__error-placeholder {
    min-height: 25px;
    margin-left: 10%;
    display: flex;
    align-items: center;
}

.user-update-form__api-error-placeholder {
    border: 1px solid var(--main-orange);
    background-color: #fcc6bd;
    margin: 8px 10%;
    padding: 8px 0px;
}

.user-update-form__error-placeholder--column {
    margin-left: 0;
}

.user-update-form__input-label {
    margin-bottom: 0px;
    margin-right: 12px;
}

.user-update-form__right-content{
 margin-top: 40px;   
}

.user-update-form__email {
    border: 1px solid #876cd3;
    border-radius: 50px;
    padding: 5px 15px;
    font-weight: 100;
    color: #6c677b;
    text-align: initial;
    width: 100%;
    background-color: white;
}

.user-update-form__validation-error {
    font-weight: 400;
    color: var(--main-red);
    text-align: initial;
    font-size: 11px;
    width: 100%;
}

.user-update__button-save{
 background-color: #876cd3;
 margin-bottom: 10px;  
}

.user-update__button-cancel{
    background-color: white;
    color: #876cd3;
    border: 1px solid #876cd3; 
    margin-bottom: 10px;   
   }

   .user-update__button-cancel:hover{
    background-color: #876cd3;
    color: white;
    border: 1px solid white; 
    margin-bottom: 10px;   
   }

.user-update-form__api-error {
    font-weight: 600;
    color: var(--main-red);
}

.user-update-form__link {
    margin-top: 12px;
    padding-left: 0px;
    font-weight: 600;
    color: var(--main-orange);
    background: transparent;
}

.user-update-form__footer {
    display: block;
    text-align: right;
    margin-top: 24px;
    border-top: 1px solid var(--grey-3);
    justify-content: center;
    font-weight: 600;
    padding: 16px 0px 0px;
}




@media (min-width: 768px) {
    .user-update-modal .modal-dialog {
        max-width: 75%;
    }
    .user-update-form__input-block--grid {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 2%;
    }
}

@media (max-width: 768px) {
    .user-update__caption-invisible{
        display: none;   
       }
}

@media (max-width: 992px) {
    .user-update-form__content {
        display: grid;
    }

    
}
