.default-stand-card {
    background-color: #edecf2;
    padding: 30px 10px;
    display: grid;
}

.default-stand-card--grid {
    grid-template-columns: 1fr;
}

.default-stand-card--preview {
    grid-template-columns: 1fr;
    text-align: center;
}

.default-stand-card__left-container {
    padding: 20px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.default-stand-card__interested-container {
    display: flex;
}

.default-stand-card__interested-button {
    cursor: pointer;
    font-size: 24px;
    background: transparent;
    transition: ease 0.3s;
}

.default-stand-card__interested-button:hover {
    color: var(--main-green);
}

.default-stand-card__interested-icon--interested {
    color: var(--main-green);
    transition: ease 0.3s;
}

.default-stand-card__interested-icon--interested:hover {
    color: var(--grey-1);
}

.default-stand-card__interested-text {
    font-weight: 700;
    padding: 0px 16px; 
    display: flex;
    align-items: center; 
}

.default-stand-card__interested-container--desktop {
    display: none;
}

.default-stand-card__interested-container--mobile {
    display: flex;
    padding-top: 16px;
}

.default-stand-card__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
}

.default-stand-card__title--regular{
    font-size: 18px;
    line-height: 19px;
    font-weight: 600;
}

.default-stand-card__title--yellow {
    color: #876cd2;
    font-weight: 800;
    font-size: 22px;
    margin-bottom: 10px;
}

.default-stand-card__description {
    font-size: 14px;
    color: #515151;
    line-height: 15px;
    font-weight: 200;
    margin-top: 20px;
    margin-bottom: 15px;
    text-align: left;
}

.default-stand-card__right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.default-stand-card__img {
    width: 100%;
    background-size: cover;
    max-width: 100%;
    margin-bottom: 20px;
    padding: 0px 20px;
    border-radius: 50px;
}

.default-stand-card__img--preview {
    width: 100%;
    background-size: cover;
    max-width: 200px;
    margin-bottom: 16px;
}

@media (min-width: 576px) {
    .default-stand-card--grid {
        grid-template-columns: 1fr 1fr;
    }
    .default-stand-card__img {
        max-width: 500px;
    }
    .default-stand-card__interested-container--mobile {
        display: none;
    }
    .default-stand-card__interested-container--desktop {
        display: flex;
    }
}
