.backend-home__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1%;
    margin: 0 auto;
    padding-top: 16px;
    width: 60%;

    justify-content: center;
}

.backend-home__section-container {
    border: 1px rgb(119, 119, 119) solid;
    border-radius: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.backend-home__section-title {
    color: white;
}

.backend-home__section-container__top {
    background-color: var(--main-orange);
    padding: 5px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.backend-home__section-container__bottom {
    padding-top: 15px;
}

.backend-home__section-icon {
    margin-right: 5px;
}

@media (min-width: 0px) and (max-width: 575px) {
    .backend-home__container {
        grid-template-columns: 1fr;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .backend-home__container {
        grid-template-columns: 1fr 1fr;
    }
}
