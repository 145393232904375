.preview-stand-button {
    position: fixed;
    bottom: 25px;
    right: 30px;
    z-index: 100;
    background-color: #876cd3; 
    color: white;
    box-shadow: 5px 6px 8px -4px rgba(0, 0, 0, 0.7);
}

.maintemplates-button:hover {
    text-decoration: none;
    opacity: 0.5;
    color: white;
}

.new-stand__input{
    border-radius: 30px;   
}

.new-stand__button-add{
   background-color: #876cd3;   
}


.maintemplates-button--squared {
    border-radius: 8px;
    padding: 8px 16px;
}