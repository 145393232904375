.lottery__header {
    padding: 16px 32px;
    display: flex;
    justify-content: space-between;
}

.lottery__header--titles {
  font-weight: 600;
  color: var(--main-green);

}

.lottery__header__button{
  text-align: right;
  width: 100%;
}
