.queres-stand-banner{
    width: 100%;
    background-color: var(--main-violet);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2%;
    text-align: center;
    padding: 50px;
}

.queres-stand__title{
    color: white;
    font-size: 60px;
    line-height: 60px;
    font-weight: 800;
    letter-spacing: -2px;
}

.queres-stand__main-button{
    margin-top: 0px;
    font-size: 20px;
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    display: block;
    margin-bottom: 10px;
    width: 50%;
    margin-left: 25%;
    background-color: white;
    color: var(--main-green);
}

.queres-stand__main-button:hover{
    background-color: var(--main-green);
    color: white;
}

.queres-stand__text{
color: white;
font-size: 14px;
margin-top: 10px;
}

@media (max-width: 767px) { 
    .queres-stand-banner{
        grid-template-columns: 1fr;
    }

    .queres-stand__main-button{
        width: 80%;
        margin-left: 10%;
    }

  .queres-stand__title{
    font-size: 40px;
    line-height: 40px;
    }
    .queres-stand__main-button{
        font-size: 20px;
        line-height: 20px;
    }

 }