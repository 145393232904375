.generic-modal__header {
    color: var(--main-green);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0px;
    font-weight: 600;
    font-size: 20px;
}

.generic-modal__close {
    position: absolute;
    background: transparent;
    right: 12px;
    top: 0px;
    color: var(--main-green);
    font-size: 24px;
}

.generic-modal-content {
    text-align: center;
    vertical-align: middle;
    padding: 16px;
}

.generic-modal__error-outline {
    border: 1px solid var(--main-orange);
    background-color: #fcc6bd;
    margin: 0px 10%;
    padding: 8px 0px;
}

.generic-modal__error-message {
    font-weight: 600;
    color: var(--main-red);
}

.generic-modal__regular-outline {
    margin: 0px 10%;
    padding: 8px 0px;
}

.generic-modal__regular-message {
    font-weight: 600;
}

.generic-modal__footer {
    display: block;
    margin-top: 24px;
    margin-right: 20px;
    border-top: 1px solid var(--grey-3);
    text-align: right;
    font-weight: 600;
    padding: 16px 0px 0px;
}

@media (min-width: 768px) {
    .generic-modal .modal-dialog {
        max-width: 75%;
    }
}
