#Search::-webkit-search-cancel-button{
  position:relative;
  right:10px;
}

.search-bar-button{
  background-color: var(--main-green);
  color: white;
  margin-left: 10px;
}

.admin-users-list__header {
  padding: 16px 32px;
  display: flex;
  display: start;
}

.search-bar__container_admin-users{
  width: 30%;
  text-align: center;
  margin-right: auto;
  display: flex;
  margin-bottom: auto;
  margin-top: auto;
}

.search-bar_admin-users{
  background-color: var(--main-white);
  color: var(--main-black);
  font-weight: 500;
  border-radius: 16px;
  border: 3px solid var(--main-green);
  margin: auto;
  padding-left: 3%;
  width: 100%;
  outline: none;
  box-shadow: none;
}

.users__container {
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px; 
  }

  .search-bar__container{
    text-align: center;
  }
  
  .search-bar {
    background-color: var(--main-white);
    color: var(--main-black);
    font-weight: 500;
    border-radius: 16px;
    border: 3px solid var(--main-green);
    margin: auto;
    padding-left: 3%;
    width: 50%;
    outline: none;
    box-shadow: none;
}

.search-bar::placeholder {
    color: var(--grey-2);
    font-weight: 400;
    padding-left: 3%;
    font-size: 14px;
}

.search-bar:focus {
    outline: none;
    box-shadow: var(--main-black);
    background-color: white;
}

.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-left:0px;
}

@media (min-width: 992px) {
  .search-bar {
    width: 30%;
  }


}



@media (min-width: 0px) and (max-width: 992px) {

      .search-bar__container_admin-users {
        width: 100%;
      }

      .admin-users-list__header{
          display: grid;
          grid-auto-columns: 1fr;
          grid-gap: 10px;
          text-align: right;
      }

    

}