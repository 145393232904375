.imagesola-stand-card {
    margin-bottom: 0px;
    
}

.imagesola-stand-card--preview {
    text-align: center;
    margin-bottom: 16px;
}

.imagesola-stand-card--firstchild {
    margin-top: 0px;
}

.imagesola-card__badge {
    position: absolute;
    top: 32%;
    right: 19%;
    z-index: 2;
}
.imagesola-stand-card__img {
    width: 100%;
}

.imagesola-stand-card__img--preview {
    width: 100%;
}
.imagesola-card__badge--preview {
    position: absolute;
    top: 32%;
    right: 19%;
    z-index: 2;
}

@media (min-width: 576px) {

    .imagesola-stand-card__img {
        max-width: 100%;
    }
    .imagesola-card__badge {
        top: 70%;
        right: 35%;
    }
    .imagesola-stand-card {
        margin-bottom: 0px;
    }
}
