.status-badge {
  font: inherit;
  padding: 8px 20px;
  border-radius: 8px;
  color: var(--main-white);
  display: inline-block;
  font-size: 14px;
}

.status-badge--active {
  background: var(--main-green);
}

.status-badge--closed {
  background: var(--main-red);
}
.status-badge--winned {
  background: var(--main-orange);
}
.status-badge--expired {
  background: var(--main-red);
}
