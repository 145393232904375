.reduced-stand-card {
    background-color: #edecf2;
    padding: 30px 10px;
    display: grid;
}

.reduced-stand-card--grid {
    grid-template-columns: 1fr;
    text-align: center;
}

.reduced-stand-card--preview {
    grid-template-columns: 1fr;
    text-align: center;
}

.reduced-stand-card__top-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reduced-stand-card__img-cropper {
    width: 100%;
}

.reduced-stand-card__img {
    width: 100%;
    background-size: cover;
    max-width: 100%;
    padding: 0px 20px;
    border-radius: 50px;
}

.reduced-stand-card__bottom-container {
    padding: 20px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.reduced-stand-card__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 21px;
}

.reduced-stand-card__description {
    font-size: 15px;
    color: var(--grey-1);
    line-height: 16px;
    font-weight: 200;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: left;
}
.reduced-stand-card__interested-container {
    display: flex;
    margin-left: 25%;
}

.reduced-stand-card__interested-button {
    cursor: pointer;
    font-size: 24px;
    background: transparent;
    transition: ease 0.3s;
}

.reduced-stand-card__interested-button:hover {
    color: var(--main-green);
}

.reduced-stand-card__interested-icon--interested {
    color: var(--main-green);
    transition: ease 0.3s;
}

.reduced-stand-card__interested-icon--interested:hover {
    color: var(--grey-1);
}

.reduced-stand-card__interested-text {
    font-weight: 700;
    padding: 0px 16px;
    display: flex;
    align-items: center;
}

.reduced-stand-card__interested-container--mobile {
    display: flex;
    padding-top: 16px;
}
