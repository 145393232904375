.templates-preview__container {
    background-color: white;
    padding-bottom: 32px;
    pointer-events: none;
    max-width: 575px;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.templates-preview__modal >.modal-dialog > .modal-content {
    max-width: 640px;
    margin: 0 auto;
}
.templates-banner__step {
    width: 180px;
    margin-bottom: 2rem;
}
.templates-banner__square {
    width: 146px;
    height: 93px;
    border-radius: 24px;   
    background: black;
    color: white;
    margin: 0 auto;
    display: flex;    
    justify-content: center;
    align-items: center;
}

.templates-banner__square-icon {
    width: 100%;
    height: 100%;
}
.templates-banner__step-texts {
    margin-top: 12px;
}

.templates-banner__step-title {
    font-weight: 600;
}
