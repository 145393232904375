.main-button {
    font: inherit;
    padding: 4px 24px;
    border-radius: 24px;
    background: var(--main-green);
    color: white;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: ease 0.3s;
}

.main-button-interested {
    font: inherit;
    padding: 4px 24px;
    border-radius: 24px;
    background: white;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: ease 0.3s;
    margin-bottom: 10px;
    margin-top: 10px;
}

.main-button--bigger {
    font-size: 18px;
    font-weight: 700;
    padding: 12px 24px;
}

.main-button:hover {
    text-decoration: none;
    opacity: 0.5;
    color: white;
}

.main-button--inactive {
    background: transparent;
    color: var(--grey-2);
    border: 1px var(--grey-2) solid;
    cursor: not-allowed !important;
    pointer-events: none;
}

.main-button--warning {
    background: var(--main-red);
}

.main-button--squared {
    border-radius: 8px;
    padding: 8px 16px;
}

.main-button--disabled {
    transition: none;
    background-color: var(--grey-2);
}

.main-button--disabled:hover {
    opacity: 1;
}

.main-button--squared-small {
    border-radius: 8px;
    padding: 4px 24px;
}

.main-button--squared-big {
    border-radius: 8px;
    font-weight: 600;
    font-size: 18px;
    padding: 16px;
}

.main-button--squared__margin{
    margin-right: 10px;
}

.main-button--squared-bigger {
    border-radius: 8px;
    padding: 20px;
}

.main-button--80-width {
    width: 80%;
    text-align: center;
}
.main-button--40-width {
    width: 30%;
    text-align: center;
}
.footer__align {
    text-align: center;
}

.ml-3 {
    margin-left: 16px;
}

.main-button--full-width {
    width: 100%;
    text-align: center;
}

.main-button--small {
    font-weight: 500;
    font-size: 12px;
}
