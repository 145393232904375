.analytics__header {
    padding: 16px 32px;
    text-align: right;   
}
.analytics-card {
  background-color: white;
  border-radius: 25px;
  margin-bottom: 30px;
}
.analytics-card--grid {
  display: grid;
  padding: 0px 20px;
  margin-top: 30px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2%;
}

.analytics-graphs-main-container{
  display: grid;
  padding: 0px 20px;
  grid-template-columns: 1fr;
}

.analytics-graph-container{
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
}

.analytics-graph-container canvas{
  width: 100% !important;
  height: auto !important;
}

.analytics-card__container {
  padding: 20px 20px 0px 20px;
  justify-content: space-between;
}

.analytics__header--titles {
  font-weight: 800;
  color: #6b687b;
  text-transform: uppercase;
  display: block;
  text-align: center;
  padding: 15px 30px;
  background-color: #b4eadc;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}

.analytics__header--number{
  font-size: 30px;
  font-weight: 900;
  text-align: center; 
  margin-top: 10px;
  color: #6b687b;
}

@media (max-width: 992px) {
  .analytics-card--grid {
    display: grid;
    padding: 0px 20px;
    margin-top: 30px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2%;
  }
}

