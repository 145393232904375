.numbertitletext-stand-card {
    max-height: 400px;
    background-color: #eeedf3;
    padding: 30px 20px;
}

.numbertitletext-stand-card--preview {
    max-height: 400px;
    text-align: center;
    margin-bottom: 16px;
}

.numbertitletext__line{
    margin: -1px 30px 0px 30px;
}

.numbertitletext-stand-card__title {
    text-transform: uppercase;
    font-size: 45px;
    font-weight: 900;
    text-align: center;
    color: #515151;
    font-family: system-ui;
}
.numbertitletext-stand-card__subtitle {
    font-size: 16px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 5px;
    margin-top: -5px;
}
.numbertitletext-stand-card__description {
    font-size: 13px;
    color: #7f7c8d;
    line-height: 16px;
    font-weight: 200;
    text-align: center;
}


@media (min-width: 576px) {

    .numbertitletext-stand-card {
        max-height: 400px;
    }
}