.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.container-lottie{
  position: fixed;
  width: 100px;
  top: 50%;
  left: 50%;
  margin-top: -50px; /* Negative half of height. */
  margin-left: -50px; /* Negative half of width. */
  background: transparent;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid var(--main-orange);
  border-color: var(--main-orange) transparent var(--main-orange) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: fixed;

  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media (max-width: 992px) { 

  /* .container-lottie{
    width: 30%;
  } */

  }