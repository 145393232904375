.category__banner {
    background: var(--main-green);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-white);
    font-weight: 600;
    font-size: larger;
    height: 150px;
}

.category__banner-image{
 width: 100%;   
}