.users-list__container {
  width: 90%;
  margin-left: 5% ;
  margin-top: 20px;
  margin-bottom: 60px;
  overflow-x:auto;
}

.users-list__container-table{
  width: 100%;
}

.users-list__container-table th{
  background-color: var(--main-green);
  color: white;
  text-align: center;
  border: 1px solid rgb(117, 117, 117);
  padding-top: 4px;
  padding-bottom: 4px;
}

.users-list__container-table td{
  border: 1px solid rgb(117, 117, 117);
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
}

.users-list__container-table label{
  margin-right: 5px;
}

.analytic-list__container-table td{
  border-bottom: 1px solid rgb(117, 117, 117);
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
}

.analytic-list__container-table{
  width: 100%;
}

.analytic-list__container-table th{
  background-color: #876cd3;
  color: white;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.analytic-list__firstchild{
  border-top-left-radius: 20px;
}

.analytic-list__lastchild{
  border-top-right-radius: 20px;
}

.analytic-list__container {
  width: 90%;
  margin-left: 5% ;
  margin-top: 20px;
  margin-bottom: 60px;
  overflow-x:auto;
}