#navbarScrollingDropdown::after {
    display: none;
}

.navbar-light .navbar-toggler {
    border: 0;
}

.navbar-light .navbar-toggler-icon {
    width: 40px;
    height: 40px;
}

.navbar__center-content {
    display: block;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    background: white;
    padding: 24px;
    z-index: 11;
}

.main-navbar {
    background: white;
}

.navbar__logo {
    margin-right: 0;
}

.navbar__logo img {
    text-align: center;
    height: 28px;
    margin-left: 20px;
}

.dropdown-toggle::after {
    display: none;
}

.nav-link {
    font-weight: bold;
    color: #363636 !important;
    padding: 0.5rem;
    transition: ease 0.3s;
}

.nav-link:hover {
    opacity: 0.7;
}

.dropdown-item {
    font-weight: 650;
}

.dropdown-item:focus,
.dropdown-item:active,
.dropdown-item.active {
    background-color: transparent;
    color: white;
}

.dropdown-item.active:hover {
    background-color: #e9ecef;
}

.navbar__dropdown .dropdown-menu {
    -webkit-filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.3));
    border: none;
    background-color: white;
}

.navbar__dropdown .dropdown-menu:after,
.navbar__dropdown .dropdown-menu:before {
    bottom: 100%;
    left: 36px;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.navbar__dropdown .dropdown-menu.dropdown-menu-right:after,
.navbar__dropdown .dropdown-menu.dropdown-menu-right:before {
    left: auto;
    right: 12px;
}

.navbar__dropdown .dropdown-menu:after {
    border-color: transparent;
    border-bottom-color: white;
    border-width: 12px;
    margin-left: -12px;
}
.navbar__dropdown .dropdown-menu:before {
    border-color: transparent;
    border-bottom-color: white;
    border-width: 12px;
    margin-left: -12px;
}

.navbar__profile-container {
    font-weight: 700;
    font-size: 14px;
    margin-left: 0px;
    color: #363636;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-right: 10px;
    border-left: 1px solid #363636;
    padding-left: 10px;
}

.navbar__profile-dropdown-info {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid var(--grey-3);
}

.navbar__profile-dropdown-greeting {
    font-weight: 600;
    color: var(--main-orange);
}

.navbar__profile-dropdown-email {
    font-weight: 600;
    font-size: 14px;
}

.navbar-nav-scroll {
    margin-right: 5%;
}

.navbar__link {
    color: white;
}

.navbar__link--small {
    font-weight: 600;
    font-size: 14px;
}

.navbar__dropdown-link--grey {
    /* color: var(--grey-1); */
    font-weight: 600;
    font-size: 14px;
}

@media (min-width: 992px) {
    .navbar__logo {
        margin-right: 1rem;
    }
    .navbar__center-content {
        display: flex;
        padding: 0;
        -webkit-filter: none;
        filter: none;
    }
    .navbar__search_box {
        width: 30%;
    }
    .navbar__dropdown-cart-container,
    .navbar__dropdown-notifications-container {
        display: block;
    }
    .navbar__dropdown-cart-container--collapsed,
    .navbar__dropdown-notifications-container--collapsed {
        display: none;
    }
}

@media (min-width: 0px) and (max-width: 575px) {
    #navbarnotification .dropdown-menu {
        margin-right: -53px !important;
        margin-top: 14px !important;
    }

    #navbarnotification
        .navbar__dropdown
        .dropdown-menu.dropdown-menu-right:after,
    .navbar__dropdown .dropdown-menu.dropdown-menu-right:before {
        left: auto;
        right: 60px;
    }
}
