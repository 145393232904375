/* Usar BEM como naming convention: 

http://getbem.com/introduction/

Construir blocks en múltiplos de 4px

Media breakpoints: 

// XS devices (Portrait phones and other)
@media (min-width: 0px) and (max-width: 575px) { ... }

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767px) { ... }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) { ... }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199px) { ... }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1399px) { ... }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { ... }
*/

/* Tipografías */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@200;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mouse+Memoirs&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap");

@import "bootstrap/dist/css/bootstrap.min.css";

:root {
    --main-yellow: #fdc80e; /* Main BTBOX */
    --main-violet: #8c6cd9;
    --main-green: #26bb92; /* Botón activo + textos + botón oportunidades */
    --main-red: #c53450; /* Botón inactivo (finalizado) + textos + botón oportunidades */

    --main-black: #333333; /* Negro principal, textos en general */
    --grey-1: #6b687b; /* Texto buscador, texto e íconos del footer */
    --grey-2: #b1b1b1; /* Texto recuadros */
    --grey-3: #f0f0f0; /* Recuadro buscador */
    --main-white: #f9f9f9; /* Recuadros, ej recuadro filtrar categorías */

    --main-orange: #ff6243; /* Principal + botón oportunidades */
    --secondary-orange: #ff62433f; /* Recuadros especiales */
    --btn-purple: #784d68; /* Botón oportunidades */
    --btn-blue: #15608a; /* Botón oportunidades */
    --btn-yellow: #f3c74a; /* Botón oportunidades */
}

body {
    background-color: #eaeaec;
    display: flex;
    flex-direction: column;
}

main {
    position: relative;
    top: -1px;
    min-height: 75.6vh;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
button {
    font-family: "Open Sans", sans-serif;
    color: var(--main-black);
}

.font-open-sans{
    font-family: "Open Sans", sans-serif; 
}

.font-roboto{
    font-family: 'Roboto', sans-serif;
}

.font-mouse-memoirs{
    font-family: 'Mouse Memoirs', sans-serif;
}

.font-monserrat{
    font-family: 'Montserrat', sans-serif;
}

.font-lato{
    font-family: 'Lato', sans-serif;
}


p {
    line-height: 1.1;
    font-weight: 500;
    font-size: 14px;
}

h1 {
    font-weight: 500;
}

h2,
h3 {
    font-weight: 700;
}

h4 {
    font-weight: 600;
    font-size: 16px;
}

button {
    border-width: 0px;
}
