.lottery-page__container{
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 30px;
    border-radius: 10px;
}

.lottery-page__container-top{
    width: 100%;
    background-image: linear-gradient(to right, var(--main-green) , var(--main-black));
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.lottery-page__main-title{
    font-size: 30px;
    font-weight: 400;
    padding: 40px 0px 0px 40px;
    color: white;
}

.lottery-page__container-info{
    display: grid;
    padding-top: 20px;
    padding-left: 40px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2%;
}

.lottery-page__container-info p {
    color: white;
    font-size: 15px;
    line-height: 30px;
}

.lottery-page__container-info span {
    font-weight: 300;
    font-size: 15px;
}

.lottery-page__container-bottom{
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 40px;
}

.lottery-page__winners{
    width: 100%;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 10px;
    padding: 20px 10px 20px 10px;
}


@media (min-width: 0px) and (max-width: 575px) {

    .lottery-page__container-info{
        grid-template-columns: 1fr;   
    }

 }

@media (min-width: 576px) and (max-width: 991px) {

    .lottery-page__container-info{
        grid-template-columns: 1fr 1fr;   
    }

 }
