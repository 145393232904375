.qr-use__add-stand{
    padding: 60px;
}

.qr-use__add-stand .backend-imageButton__container{
    width: 400px;
}

.swiper-container{
    background-color: white;
}
.big-wrapper-card {
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0 auto;
    text-align: center;
    width: 70%;
    margin-left: 15%;
    display: flex;
    justify-content: space-between;
}

.display-sm-true{
    display: none;
}


.swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: var(--main-violet) !important;
}

.swiper-example-qr__img {
    border: 1px solid var(--main-green);
    width: 150px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 12px;
    float: left;
}

.swiper-escanea-text{
    color: var(--main-violet);
    font-size: 20px;
    font-weight: 800;
    text-align: left;
    float: right;
    padding-top: 40px;

}

.swiper-button-prev, .swiper-button-next{
    background-color: var(--main-violet);
    border-radius: 50%;
    
    width: 35px !important;
    height: 35px !important;

}
.swiper-button-prev{
    left: 40px !important; 
}

.swiper-button-next{
    right: 40px !important; 
}

.swiper-button-prev:after, .swiper-button-next:after{
    font-size: 14px !important;
    color: white;
}

.text-black {
    color: #000;
}

.m-t-0, .m-y-0 {
    margin-top: 0!important;
}
.text-center {
    text-align: center;
}
.m-t-0 {
    margin-top: 0!important;
}
.text-center {
    text-align: center;
}

.swiper-title {
    color: #616262;
    font-size: 40px ;
    font-weight: 900 ;
    line-height: 35px;
    margin-bottom: 35px;
}

.fw-300 {
    font-weight: 300 !important;
}
.align-items-center {
    align-items: center;
}
.flex-container {
    display: flex;
}
.block {
    width: 100%;
    padding: 60px 30px;
    color: inherit;
    background-color: #fff;
}

.p-y-lg {
    padding-bottom: 60px!important;
}
.home-page-big-outer {
    position: relative;
}
.home-page-big-outer .img-responsive {
    object-fit: contain!important;
    height: 100%;
    width: 100%;
    max-width: 550px;
}
.width-half-content.cs-one {
    padding-top: 45px;
}
.width-half-content {
    max-width: 450px;
}
.swiper-text{
    font-weight: 300 !important;
    font-size: 14px !important;
    line-height: 20px !important;
}
.p-t {
    padding-top: 20px!important;
}
.container, .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
}
.container, .container-fluid {
    margin-right: auto;
    margin-left: auto;
}
@media (max-width: 768px){

    .qr-use__add-stand{
        padding: 10px;
    }
    
    .qr-use__add-stand .backend-imageButton__container{
        width: auto;
    }
    


    .swiper-button-prev{
        left: 10px !important; 
    }
    
    .swiper-button-next{
        right: 10px !important; 
    }

    .display-sm-none{
        display: none;
    }

    .display-sm-true{
        display: block;
        width: 100%;
    }

    .home-page-big-outer .img-responsive {
        object-fit: contain!important;
        height: 100%;
        width: 100%;
        max-width: 768px;
    }
}
@media (min-width: 992px){
    .block {
        padding: 80px 0;
    }
    .pt-md-10 {
        padding-top: 10px;
    }
    .pl-md-2 {
        padding-left: 30px;
    }
    .width-half {
        width: 40%;
    }
    .width-half-v1{
        width: 50%;
    }
    .pr-md-2 {
        padding-right: 30px;
    }
    .container {
        width: 880px;
    }
    
}
@media (max-width: 1023px){
    .flex-direction-column-reverse-sm {
        flex-direction: column-reverse;
    }
    .flex-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
}

@media (max-width: 480px){
    .swiper-title{
        font-size: 25px;
    }

    .swiper-example-qr__img {
        float: none;
    }
    .swiper-escanea-text{
        float: none;
        text-align: center;
    }

}





