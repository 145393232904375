.stand-banner {
    background-size: cover;
    background-position: center;
    width: 100%;
    margin-bottom: 20px;
}

#videoBanner{
    z-index: -1;
    width: 100%;
    background-color: transparent;
}

.stand-banner-home {
    background-size: cover;
    background-position: center;
    width: 100%;
}
.video-banner__title {
    text-align: center;
    padding: 36px 0px;
    margin-bottom: 24px;
    margin-top: 24px;
}
.button-banner {
    text-align: center;
    padding: 36px 0px;
    margin-bottom: 12px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;
    font-size: large;
    width: 30%;
}
.homeform-page__info--container{
    display: grid;
    grid-template-columns: 1fr;
}

.example-qr__img{
    border: 1px solid var(--main-green);
    width: 200px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 12px;
}

@media (min-width: 992px) {

    .homeform-page__info--container{
        display: grid;
        grid-template-columns: 40% 60%;
    }
    
}

.main-button--whatsapp{
  position: fixed !important;
  right: 10px;
  bottom: 15px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #25d366;
  line-height: 65px;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 500;
  animation-name: pulse;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
	}
	80% {
		box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
	}
}

.main-icon--whatsapp{
    color: white;
    font-size: 25px;
    margin-top: -20px;
    margin-left: -5px;
}