.avatar {
  width: 100%;
  height: 100%;
  display: flex; 
  justify-content: center;
  align-items: center;
}

.avatar img {
  display: block;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar--bordered img {
  border: 1px solid var(--main-orange);
}

.avatar--bordered-grey img {
  border: 2px solid var(--grey-1);
}