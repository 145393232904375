.homepage-form {
    text-align: center;
    vertical-align: middle;
    padding: 16px;
}
.homepage-form__background {
    background-color: var(--main-white);
    margin-bottom: 6px;
}
.homepage-form__title--big {
    padding: 32px 0px 0px 16px;
    font-weight: 800;
    font-size: 22px;
    color: black;
    margin-left: 10%;
    margin-bottom: 5px;

}
.homepage-form__input-block--grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2%;
    margin-left: 10%;
    width: 80%;
}

.homepage-form__input-block--column {
    width: 100%;
    text-align: justify;
}
.homepage-form__button-block--grid {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    width: 80%;
    margin-left: 10%;
}

.homepage-form__button-block--column {
    width: 100%;
    text-align: center;
}

.homepage-form__form-tag {
    display: flex;
    margin-left: 10%;
    margin-top: 12px;
}

.homepage-form__form-input {
    /* width: 80%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid var(--grey-3); */
    border: 1px solid #876cd3;
    border-radius: 50px;
    padding: 5px 15px;
    font-weight: 100;
    color: #6c677b;
    text-align: initial;
    width: 80%;
    background-color: white;
}
.homepage-form__select-input {
    width: 80%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid var(--grey-3);
}
.homepage-form__select-input::after {
    border-top: 6px solid #f00;
}

.homepage-form__form-input--inline {
    width: 100%;
}

.homepage-form__form-input::placeholder {
    color: var(--grey-2);
}

input:focus.homepage-form__form-input {
    outline-color: var(--main-green);
}

.homepage-form__error-placeholder {
    min-height: 25px;
    margin-left: 10%;
    display: flex;
    align-items: center;
}

.homepage-form__error-placeholder--column {
    margin-left: 0;
}

.homepage-form__input-label {
    margin-bottom: 0px;
    margin-right: 12px;
}

.homepage-form__validation-error {
    font-weight: 400;
    color: var(--main-red);
    text-align: initial;
}

.homepage-form__link {
    margin-top: 12px;
    padding-left: 0px;
    font-weight: 600;
    color: var(--main-green);
    background: transparent;
}

.homepage-form__footer {
    margin-top: 24px;
    border-top: 1px solid var(--grey-3);
    justify-content: center;
    font-weight: 600;
    padding: 16px 0px 0px;
    
}

