.backend-imageButton__link{
  text-decoration: none;
}

.backend-imageButton__link:hover{
  opacity: 70%;
}

.backend-imageButton__container{
  width: 300px;
}

.backend-imageButton__text{
  position: relative;
  bottom: 33px;
  text-align: center;
  margin-bottom: 0px;
}

.backend-imageButton__icon{
  margin-top: -2px;
  margin-right: 5px;
}