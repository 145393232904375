.potencia-marca-banner{
    width: 100%;
    background-color: var(--main-violet);
    text-align: center;
    padding: 50px;
}

.potencia-marca__title{
    color: white; 
    font-size: 30px;
    font-weight: 600; 
    letter-spacing: -1px;
}

.potencia-marca__text{
    font-size: 20px;
    font-weight: 100;
    line-height: 30px;
    color: white;
    padding: 0px 140px;
}

@media (max-width: 800px) {
    .potencia-marca__text{
        padding: 0px 10px;
    }
   
}