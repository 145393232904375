.ecommerce-stand-card {
    background-color: white;
    position: relative;
    border-radius: 25px;
    padding: 0px 0px 30px 0px;
    margin-bottom: 30px;
    display: grid;
    margin: 10px;
}

.ecommerce-stand-card--grid {
    grid-template-columns: 1fr;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
}

.ecommerce-stand-card--preview {
    grid-template-columns: 1fr;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 40px;
}

.ecommerce-stand-card__top-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.ecommerce-stand-card__img {
    max-height: 400px;
    max-width: 90%;
    background-size: cover;
    margin-bottom: 16px;
    border-radius: 25px 25px 0px 0px;
    margin-left: auto;
    margin-right: auto;
}

.ecommerce-stand-card__bottom-container {
    padding: 0px 20px 0px 20px;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ecommerce-stand-card__description {
    text-align: center;
    font-size: 19px;
    color: var(--grey-1);
    letter-spacing: 0;
    font-weight: 600;
    margin-top: 10px;
    line-height: 20px;
}
.ecommerce-stand-card-cupon__container {
    font-weight: 700;
    text-align: center;
    font-size: 1.8rem;
    display: block;
    margin-bottom: 20px;
}
.ecommerce-stand-card-cupon__description {
    text-align: center;
    font-size: 1.2rem;
    color: var(--grey-1);
    padding-right: 16px;
    letter-spacing: 0;
    font-weight: 100;
    margin-top: 30px;
    line-height: 1.4rem;
}
.ecommerce-stand-card__price {
    font-size: 14px;
    text-align: center;
    line-height: 14px;
    font-weight: 400;
    margin-top: 16px;
    text-decoration: line-through;
}
.ecommerce-stand-card__pricediscount {
    font-weight: 700;
    text-align: center;
    font-size: 40px;
    display: block;
    margin-bottom: 20px;
}
.ecommerce-stand-card__interested-container {
    display: flex;
    justify-content: center;
}

.ecommerce-stand-card__interested-button {
    cursor: pointer;
    font-size: 24px;
    background: transparent;
    transition: ease 0.3s;
}

.ecommerce-stand-card__interested-button:hover {
    color: var(--main-green);
}

.ecommerce-stand-card__interested-icon--interested {
    color: var(--main-green);
    transition: ease 0.3s;
}

.ecommerce-stand-card__interested-icon--interested:hover {
    color: var(--grey-1);
}

.ecommerce-stand-card__interested-text {
    font-weight: 700;
    padding: 0px 16px;
    display: flex;
    align-items: center;
}

.ecommerce-stand-card__interested-container--mobile {
    display: flex;
    padding-top: 16px;
}
.ecommerce-badge-text {
    font-weight: 900;
}
.ecommerce-card__badge {
    position: absolute;
    left: -10px;
    top: -10px;
    z-index: 2;
}
