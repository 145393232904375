.card-edition-form__input-block--grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2%;
    width: 80%;
    margin-left: 10%;
}

.card-edition-form__input-block--column > .generic-form__input-block {
    width: 100%;
    margin: 0;
}

.card-edition-form__input-block--taller {
    min-height: 110px;
    flex-direction: column;
    display: flex;
    justify-content: end;
}

.card-edition-form__input-block--column > .generic-form__input-block > label {
    font-size: 12px;
}