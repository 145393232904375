.textsolo-stand-card {
    max-height: 400px;
    margin-bottom: 16px;
}

.textsolo-stand-card--preview {
    max-height: 400px;
    text-align: center;
    margin-bottom: 16px;
}

.textsolo-stand-card--firstchild {
    margin-top: 10px;
}

.textsolo-stand-card__title {
    font-size: 20px;
    line-height: 22px;
    margin-top: 35px;
    margin-bottom: 20px;
    font-weight: 500;
    text-align: center;
}
.textsolo-stand-card__description {
    font-size: 20px;
    color: var(--grey-1);
    padding-right: 16px;
    line-height: 1.7;
    font-weight: 200;
    margin-top: 30px;
    text-align: center;
}

@media (min-width: 576px) {

    .textsolo-stand-card {
        max-height: 400px;
        margin-bottom: 76px;
    }
}