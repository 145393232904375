.accordion-stand-card__title {
    font-size: 36px;
    font-weight: 500;
    line-height: 1;
}

.accordion-stand-card__title--yellow{
    font-size: 18px !important;
}

.accordion-stand-card__description {
    font-size: 15px;
    text-align: left;
    font-weight: 200;
}

.accordion-item{
 color: #6b687b !important;   
}

.accordion-stand-card{
    margin-bottom: 10px;
    padding: 5px 20px 0px 20px;
}
