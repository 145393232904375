.generic-form {
    text-align: center;
    vertical-align: middle;
    padding: 0px 16px 16px 16px;
}

.generic-form__background {
    margin-bottom: 6px;
}

.generic-form__back-button {
    background-color: var(--grey-2);
    color: white;
}

.generic-form__card {
    margin: 2rem auto 4rem auto;
    padding: 0px;
    text-align: center;
}

.generic-form__title--big {
    color: var(--main-green);
    padding: 32px 0px 0px 16px;
    font-weight: 800;
    font-size: 22px;
    margin-bottom: 5px;
}

.generic-form__input-block {
    width: 80%;
    margin: 0 auto;
    text-align: justify;
}

.generic-form__input-block--grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2%;
    margin-left: 10%;
    width: 80%;
}

.generic-form__input-block--grid-nomargin {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2%;
}

.generic-form__input-block--column {
    width: 100%;
}
.generic-form__button-block--grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2%;
    margin-left: 15%;
    width: 80%;
}

.generic-form__button-block--column {
    width: 100%;
    text-align: justify;
}

.generic-form__form-tag {
    display: flex;
    margin-left: 10%;
    margin-top: 12px;
}

.color-picker__holder {
    position: relative;
}

.color-picker__button {
    position: absolute;
    right: 10px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: linear-gradient(
        135deg,
        rgba(255, 0, 0, 1) 0%,
        rgba(255, 154, 0, 1) 10%,
        rgba(208, 222, 33, 1) 20%,
        rgba(79, 220, 74, 1) 30%,
        rgba(63, 218, 216, 1) 40%,
        rgba(47, 201, 226, 1) 50%,
        rgba(28, 127, 238, 1) 60%,
        rgba(95, 21, 242, 1) 70%,
        rgba(186, 12, 248, 1) 80%,
        rgba(251, 7, 217, 1) 90%,
        rgba(255, 0, 0, 1) 100%
    );
    top: -30px;
    z-index: 20;
}

.generic-form__form-input {
    width: 80%;
    padding: 8px 20px;
    border-radius: 30px;
    border: 1px solid var(--grey-3);
    background-color: white;
    color: #6c677b;
}

.generic-form__form-input--file-upload {
    padding: 8px 15px;
    border: none;
}

.generic-form__form-input--inline {
    width: 100%;
}
.generic-form__form-input--textarea {
    height: 260px;
}

.generic-form__form-input::placeholder {
    color: var(--grey-2);
}

input:focus.generic-form__form-input {
    outline-color: var(--main-orange);
}

.generic-form__input-block label{
    text-align: left;
    color: #838385;
    font-size: 14px;
    margin-bottom: 10px;
}

.generic-form__error-placeholder {
    min-height: 25px;
    margin-left: 10%;
    display: flex;
    align-items: center;
}

.generic-form__error-placeholder--column {
    margin-left: 0;
}

.generic-form__input-label {
    margin-bottom: 0px;
    margin-right: 12px;
}
.generic-form__input-label-lottery {

    font-weight: 600;
    font-size: 16px;
    margin-bottom: 5px;
}

.generic-form__validation-error {
    font-weight: 700;
    color: var(--main-red);
    text-align: initial;
}

.generic-form__link {
    margin-top: 12px;
    padding-left: 0px;
    font-weight: 600;
    color: var(--main-orange);
    background: transparent;
}

.generic-form__fieldarray-card {
    position: relative;
    background: #f0f0f0;
    border-radius: 10px;
    padding: 16px 0px;
    margin: 16px 0px;
}

.generic-form__fieldarray-card-movebuttons {
    position: absolute;
    right: 16px;
    top: 16px;
    display: flex;
}

.generic-form__fieldarray-card-position {
    line-height: 2;
    font-weight: 600;
    color: var(--main-green);
}

.generic-form__footer {
    margin-top: 24px;
    border-top: 1px solid var(--grey-3);
    justify-content: center;
    font-weight: 600;
    padding: 16px 0px 0px;
}

.generic-form__api-error-placeholder {
    border: 1px solid var(--main-orange);
    background-color: #fcc6bd;
    margin: 0px 10%;
    padding: 8px 0px;
}

.generic-form__api-error {
    font-weight: 600;
    color: var(--main-red);
}

@media (min-width: 768px) {
    .generic-form__input-block--grid {
        grid-template-columns: 1fr 1fr;
    }
    .generic-form__input-block--grid-nomargin {
        grid-template-columns: 1fr 1fr;
    }
    .generic-form__card {
        padding: 0px 4rem;
    }
}
