.mail-update-modal__header {
    color: var(--main-orange);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0px;
    font-weight: 600;
    font-size: 26px;
}
.mail-update-modal__title {
    color: var(--main-orange);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0px;
    font-weight: 600;
    font-size: 32px;
}

.mail-update-modal__close {
    position: absolute;
    background: transparent;
    right: 12px;
    top: 0px;
    color: var(--main-orange);
    font-size: 24px;
}

.mail-update-form {
    text-align: center;
    vertical-align: middle;
    padding: 16px;
}

.mail-update-form__avatar-container {
    margin-left: 10%;
    align-items: center;
}

.mail-update-form__avatar-buttons-container {
    font-weight: 600;
    font-size: 14px;
    text-align: initial;
}

.mail-update-form__input-block--grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2%;
    margin-left: 10%;
    width: 80%;
    margin-bottom: 2%;
}

.mail-update-form__input-block--column {
    width: 100%;
    text-align: justify;
    margin-bottom: 2%;
}


.mail-update-form__form-tag {
    display: flex;
    margin-left: 10%;
    margin-top: 12px;
}

.mail-update-form__form-input {
    width: 80%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid var(--grey-3);
}

.mail-update-form__form-input--inline {
    width: 100%;
}

.mail-update-form__form-input::placeholder {
    color: var(--grey-2);
}

input:focus.mail-update-form__form-input {
    outline-color: var(--main-orange);
}

.mail-update-form__error-placeholder {
    min-height: 25px;
    margin-left: 10%;
    display: flex;
    align-items: center;
}

.mail-update-form__api-error-placeholder {
    border: 1px solid var(--main-orange);
    background-color: #fcc6bd;
    margin: 8px 10%;
    padding: 8px 0px;
}

.mail-update-form__error-placeholder--column {
    margin-left: 0;
}

.mail-update-form__input-label {
    margin-bottom: 0px;
    margin-right: 12px;
}
.mail-text {
    text-align: center;
    display: block;
    font-weight: 600;
    font-size: 20px;
    color: gray;
    margin-bottom: 4rem;

}


.mail-update-form__email {
    margin-left: 10%;
    margin-bottom: 2%;
    font-weight: 600;
    color: var(--main-black);
    text-align: initial;
}

.mail-update-form__validation-error {
    font-weight: 400;
    color: var(--main-red);
    text-align: initial;
}

.mail-update-form__api-error {
    font-weight: 600;
    color: var(--main-red);
}

.mail-update-form__link {
    margin-top: 12px;
    padding-left: 0px;
    font-weight: 600;
    color: var(--main-orange);
    background: transparent;
}

.mail-update-form__footer {
    display: block;
    margin-top: 24px;
    border-top: 1px solid var(--grey-3);
    justify-content: center;
    font-weight: 600;
    padding: 16px 0px 0px;
}

@media (min-width: 576px) {
    .mail-update-form__avatar-container {
        display: flex;
    }
}


@media (min-width: 768px) {
    .mail-update-modal .modal-dialog {
        max-width: 75%;
    }
    .mail-update-form__input-block--grid {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 2%;
    }
}
