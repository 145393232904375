.analytics-form {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 16px 0px;
    border-radius: 16px;
    max-width: 400px;
}

.analytics-form--disabled {
    opacity: 0.5;
}

.bulk-upload-forms {
    display: grid;
    margin: 16px 10px;
    justify-content: center;
}

.bulk-upload-forms__arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bulk-upload-forms__arrow {
    color: var(--main-green);
    font-size: 36px;
    font-weight: 700;
    transform: rotate(90deg);
}

.bulk-upload-forms__tooltip-container {
    transition: 0.3 ease-in-out;
    color: var(--main-yellow);
    cursor: help;
    margin-left: 15px;
}

.bulk-upload-forms__tooltip{
    font-size: 28px;
    background-color: #6fd3b9;
    border-radius: 50%;
    color: white; 
    border: 1px solid #6fd3b9;  
}

.bulk-upload-forms__top-container{
    background-color: #b4eadc;
    padding: 24px;
    width: 100%;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
}

.bulk-upload-forms__tooltip-purple{
    font-size: 28px;
    background-color: #ab9ae0;
    border-radius: 50%;
    color: white; 
    border: 1px solid #ab9ae0;  
}


.bulk-upload-forms__top-container-purple{
    background-color: #cec6ed;
    padding: 24px;
    width: 100%;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
}

.bulk-upload-forms__bottom-container{
    background-color: #f5f4f9;
    padding: 24px;
    width: 100%;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
}

.bulk-upload-forms__tooltip-container:hover {
    opacity: 0.7;
}

.disabled {
    pointer-events: none;
}

.example-modal {
    display: flex;
    justify-content: center;
}

.analytics-form__title {
    font-size: 18px;
    line-height: 19px;
    display: flex;
    font-weight: 700;
}

.analytics-form__input {
    width: 300px;
    max-width: 100%;
    color: #444;
    padding: 8px;
    font-size: 10px;
    margin-bottom: 24px;
    background: #fff;
    border-radius: 10px;
    padding: 30px;
    text-align: center;
}

.analytics-form__input::file-selector-button {
    border: none;
    background: #eeedf3;
    border-radius: 10px;
    padding: 15px;
    display: block;
    padding: 10px 20px;
    border-radius: 10px;
    color: #6b687b;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    width: 100%;
    margin-bottom: 10px;
}

.analytics-form__input::file-selector-button:hover {
    background-color: #6b687b;
    color: #eeedf3;
}

.bulk__import-container{
    text-align: right;
}

.bulk__import-button{
    background-color: #26bb93 !important;
    text-align: center;
}

.bulk__import-button-purple{
    background-color: #876cd3 !important;
    text-align: center;
}

.error-table {
    max-width: 90%;
}

@media (min-width: 940px) {
    .bulk-upload-forms {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
    }
    .bulk-upload-forms__arrow {
        transform: rotate(0deg);
    }
    .analytics-form__input {
        width: 420px;       
        font-size: 16px;
    }
}
