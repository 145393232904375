.video-stand-card {
    height: auto;
    margin-bottom: 0px;
    background-color: #e2e1e9;
    padding: 20px;
}

.video-stand-card--preview {
    text-align: center;
}

.video-stand-card--firstchild {
    margin-top: 0px;
}

.video-stand-card__title {
    font-size: 22px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 5px;
}

.video-stand-card__subtitle {
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 10px;
}

.video-stand-card__title--green {
    color: var(--main-green);
    font-weight: 600;
}

.video-stand-card__iframe {
    width: 100%;
    height: 280px;
    border-radius: 25px;
}
