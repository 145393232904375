.bloated-button {
    margin: 8px;
    border-radius: 12px;
    height: auto;
    min-width: 220px;
    max-width: 300px;
    background-color: var(--main-orange);
    transition: ease 0.3s;
    text-align: center;
    text-decoration: none;
}

.bloated-button__content {
    font-size: 20px;
    padding: 16px;
    color: var(--main-white);
    font-weight: 700;
    transition: ease 0.3s;
    text-align: center;
    
}

.bloated-button:hover,
.bloated-button__content:hover {
    text-decoration: none;
    transform: scale(1.1);
}

.bloated-button--green {
    background-color: var(--main-green);
}

.bloated-button--purple {
    background-color: var(--btn-purple);
}
.bloated-button--violet {
    background-color: var(--main-violet);
}

.bloated-button--orange {
    background-color: var(--main-orange);
}

.bloated-button--blue {
    background-color: var(--btn-blue);
}

.bloated-button--yellow {
    background-color: var(--btn-yellow);
}

.bloated-button--red {
    background-color: var(--main-red);
}