.stand-preview__container {
    background-color: #EAEAEC;
    padding-bottom: 32px;
    pointer-events: none;
    max-width: 575px;
    margin: 0 auto;
}

.stand-preview__modal >.modal-dialog > .modal-content {
    max-width: 640px;
    margin: 0 auto;
}