.titletext-stand-card {
    max-height: 400px;
    text-align: center;
    margin-bottom: 16px;
}

.titletext-stand-card--preview {
    max-height: 400px;

    margin-bottom: 16px;
}

.titletext-stand-card--firstchild {
    margin-top: 0px;
}
.titletext-stand-card__title {
    font-size: 20px;
    line-height: 22px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 500;
    text-align: center;
}
.titletext-stand-card__description {
    font-size: 15px;
    color: var(--grey-1);
    line-height: 16px;
    font-weight: 200;
    margin-top: 10px;
    text-align: center;
}


@media (min-width: 576px) {

    .titletext-stand-card {
        max-height: 400px;
        margin-bottom: 76px;
    }
}