.nosotros-container{
    background-color: #f8f9fa;
    padding: 36px 0px;
    padding-bottom: 24px;
}

.nosotros-info__container{
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 4%;
    margin: 0 auto;
    width: 80%;
    justify-content: center;
    margin-bottom: 20px;
}

.nosotros-banner__title {
    margin-top: 20px;
    margin-bottom: 45px;
    font-size: 38px;
    font-weight: 800;
    text-align: left;
    padding-left: 60px;
    color: var(--main-violet);
    letter-spacing: -2px;
}

.nosotros-info__text p{
  font-weight: 300;
  font-size: 14px;
  line-height: 23px;
  margin-bottom: 20px;
}
.nosotros-info__text span{
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.nosotros-info__important{
  color: var(--main-violet);
  font-size: 20px !important;
  line-height: 24px !important;
  font-weight: 900 !important;
}

.nosotros-banner__arrow-black {
    margin-bottom: 12px;
    margin-right: 18px;
    box-sizing: border-box;
    position: relative;
    display: none;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    width: 2%;
}

.nosotros-banner__arrow-black::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 3px solid black;
    border-right: 3px solid black;
    transform: rotate(45deg);
    right: 0;
    bottom: -5px;
}

@media (min-width: 1199px) {
   
    .nosotros-banner__arrow-black {
        display: inline-block;
    }
}

.nosotros-video__media{
    box-shadow: 10px 10px 21px 6px var(--main-violet);
    width: 300px;
    height: 315px;
    }
  
  .nosotros-video__container:before {
    content: '';
    z-index: 2;
    position: absolute;
    text-align: center;
    width: 300px;
    height: 315px;
    box-shadow: 0 0 0 8px var(--main-violet);
    pointer-events: none;
    border-radius: 20px;
  }
  .nosotros-video__container{
    text-align: center;
    margin-bottom: 20px;
  }

  @media (min-width: 0px) and (max-width: 575px) { 

    .nosotros-video__media{
     
      width: 210px;
      height: 157px;
      }

      .nosotros-video__container:before {
      width: 210px;
      height: 157px;
      }

      .nosotros-info__container{
        display: grid;
        grid-template-columns: 1fr;
      }
   }



@media (min-width: 576px) and (max-width: 991px) { 

  .nosotros-info__container{
    display: grid;
    grid-template-columns: 1fr;
  }

 }


