.qrcode__header {
    padding: 16px 32px;
    display: flex;
    justify-content: space-between;
}

.qrcode__header--titles {
  font-weight: 600;
  color: var(--main-green);

}

.qr-file__container{
  width: 100%;
}

.qr-file__input{
  background-color: white;
  border-radius: 30px; 
  padding: 8px 20px;
  color: #6c677b;
}

.generic-form__card canvas{
  border-radius: 30px;
}
