.functionality-swiper-container{
 height: 60vh;
  width: 100%;
  display: flex;
  align-items: center;
}


.functionality-container{
   padding: 36px 0px; 
   text-align: center;
   width: 100%;
   background-color: var(--main-green);
 }

 .functionality__title {
  margin-top: 20px;
  font-size: 38px;
  font-weight: 800;
  text-align: center;
  color: white;
  letter-spacing: -2px;
}

 .functionality__arrow-white {
  margin-bottom: 12px;
  margin-right: 18px;
  box-sizing: border-box;
  position: relative;
  display: none;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  width: 2%;
}

.functionality__arrow-white::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 3px solid white;
  border-right: 3px solid white;
  transform: rotate(45deg);
  right: 0;
  bottom: -5px;
}

@media (min-width: 1199px) {
 
  .functionality__arrow-white {
      display: inline-block;
  }
}

.photos {
  align-items: center;
  width: 100%;
  height: auto;
  margin-right: 0;
}

.functionality__text{
  color: white;
  width: 80%;
  margin-left: 10%;
  line-height: 17px;
}

.functionality-container .swiper-pagination-bullet{
  color: white !important;
  background: white;
  opacity: 100;
}

.swiper-3d .swiper-slide-shadow-left {
  background-image: none !important; 
}

.swiper-3d .swiper-slide-shadow-right {
  background-image: none !important;
}

.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
 bottom: 0px;
}
