.visited-list-title{
    padding: 20px;
    font-size: 40px;
    text-decoration: underline;
}

.visited-item-container{
    display: flex;
    width: 400px;
    height: 100px;
    margin: 10px;
}

.visited-list-container{
    position: relative;
    margin-top: 10px;
    padding: 10px 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.visited-lists-image{
    width: 150px;
  
}

.visited-list-image-container{
    height: 100%;
    display: flex;
    align-items: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: var(--main-green);
    border-right: 1px solid white;
}

.visited-list-info-container{
    width: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: var(--main-green);
    color: white;
}

.visited-list-info__title{
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
    padding-top: 15px;
}

.visited-list-info__button{
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    border-radius: 24px;
    background: white;
    width: 70%;
    height: 30px;
    transition: ease 0.3s;
    margin-right: auto;
    margin-left: auto;
    margin-top: 5%;
}
.visited-list-info__button:hover {
    text-decoration: none;
    opacity: 0.5;
    color: white;
}

.visited-list-info__button a{
    color: var(--main-green);
    text-decoration: none;
    font-size: 14px;
    width: 100%;
    font-weight: 800;
    margin-top: auto;
    margin-bottom: auto;
}

.visited-list-info__button a:hover{
    color: var(--main-green);
}

@media (min-width: 0px) and (max-width: 992px) {

    .visited-item-container{
        display: flex;
        width: 100%;
        height: 100px;
    }


}