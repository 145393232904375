.stand-banner {
    background-size: cover;
    background-position: center;
    width: 100%;
    margin-bottom: 20px;
}

.main-stand-container {
    position: relative;
    margin-top: 0px;
    text-align: center;
}

.container{
    padding: 0px;
    margin: 0px;
    margin-left: auto;
    margin-right: auto;
}

.stand__contact-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.stand__contact-button {
    margin: 8px;
}

.stand__contact-button-text {
    display: flex;
    align-items: center;
}

.stand__contact-button-icon {
    margin-right: 8px;
    font-size: 24px;
}

.stand__networks {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 16px 0px;
    text-align: center;
}

.stand__network-button {
    margin: 0px 8px;
    font-size: 30px;
    color: var(--main-black);
    transition: ease 0.3s;
}

.stand__network-button:hover {
    color: inherit;
    opacity: 0.7;
}


@media (min-width: 575px) {
    .main-stand-container {
        text-align: initial;
    }
}
