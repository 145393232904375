.disclaimer-banner {
    background: var(--secondary-green);
    text-align: center;
    padding: 36px 0px;
    margin-bottom: 24px;
}

.disclaimer-banner__title {
    margin-top: 20px;
    margin-bottom: 45px;
    font-size: 38px;
    font-weight: 800;
    text-align: left;
    padding-left: 60px;
    color: var(--main-green);
    letter-spacing: -2px;
}

.disclaimer-banner__content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;    
    flex-wrap: wrap;
    padding: 0px 70px;
}

.disclaimer-banner__step {
    width: 180px;
}

.disclaimer-banner__circle {
    width: 90px;
    height: 90px;
    border-radius: 100%;
    background: var(--main-green);
    color: white;
    margin: 0 auto;
    display: flex;    
    justify-content: center;
    align-items: center;
}

.disclaimer-banner__circle-icon {
    width: 60%;
    height: 60%;
}

.disclaimer-banner__step-texts {
    margin-top: 12px;
    height: auto;
}

.disclaimer-banner__step-title {
    font-weight: 800;
    font-size: 18px;
}

.disclaimer-banner__step-text {
    font-weight: 300;
    font-size: 13px;
}

.disclaimer-banner__arrow {
    margin-bottom: 30px;
    box-sizing: border-box;
    position: relative;
    display: none;
    border-top: 2px solid var(--main-green);
    border-bottom: 2px solid var(--main-green);
    width: 8%;
}

.disclaimer-banner__arrow::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 16px;
    height: 16px;
    border-top: 3px solid var(--main-green);
    border-right: 3px solid var(--main-green);
    transform: rotate(45deg);
    right: 0;
    bottom: -8px;
}

.disclaimer-banner__arrow-black {
    margin-bottom: 12px;
    margin-right: 18px;
    box-sizing: border-box;
    position: relative;
    display: none;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    width: 2%;
}

.disclaimer-banner__arrow-black::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 3px solid black;
    border-right: 3px solid black;
    transform: rotate(45deg);
    right: 0;
    bottom: -5px;
}

@media (max-width: 1198px){

    .disclaimer-banner__title {
        text-align: center;
        padding-left: 0;
    }

}

@media (min-width: 1199px) {
    .disclaimer-banner__arrow {
        display: flex;
    }

    .disclaimer-banner__arrow-black {
        display: inline-block;
    }
}


