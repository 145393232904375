.stands-list-title{
    padding: 20px;
    font-size: 40px;
    text-decoration: underline;
}
.stands-list__header {
    padding: 16px 32px;
    display: flex;
    display: start;
}
.stands-list__header__button{
    margin-right: 10px;
}

.stands-list__see__button{
    background-color: #876cd3 !important;
    width: 75%;
    text-align: center;
}

.stands-item-container{
    display: flex;
    max-width: 400px;
    background-color: #f3f2f8;
    border-radius: 40px;
    box-shadow: 10px;
    height: 190px;
    margin: 10px;
    box-shadow: 5px 5px 5px 5px rgb(152 152 152 / 11%)
}

.stand-left-container{
    text-align: center;
}

.stands-list-container{
    position: relative;
    margin-top: 10px;
    padding: 10px 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.stands-lists-image{
    width: 150px;
  
}

.stands-list-image-container{
    display: flex;
    height: 70%;
    align-items: center;
    background-color: white;
    border-radius: 30px;
    margin: 10px;
}

.stands-list-info-container{
    width: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: transparent;
    color: white;
}

.stands-list-info__title{
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
    padding-top: 15px;
    color: #373737;
    padding-bottom: 10px;
    border-bottom: 1px solid #6a6a6a5a;
}


.standlist-imageButton__icon{
    margin-top: -2px;
    margin-right: 10px;
}

.standlist-imageButton__iconblack{
    margin-top: -2px;
    margin-right: 10px;
}

.stands-list__back-button{
    background-color: transparent;
    border: 1px solid #876cd3;
    color: #373737;
    margin-left: 40px;
    margin-bottom: 40px;   
}

.stands-list__back-button:hover{
    background-color: #876cd3;
    color: white;
    opacity: 100;
}

.stands-list-info__button{
    text-align: right;
    padding-right: 9px;
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    
}

.stands-list-info__button a{
    color: #6e6a7b;
    text-decoration: none;
    font-size: 13px;
    padding-left: 50px;
    text-align: left;
    width: 100%;
    margin-bottom: 5px;
}

.stands-list-info__button a:hover{
    color: blue;
}
.search-bar__container_stands{
    width: 30%;
    text-align: center;
    margin-right: auto;
  }

  .search-bar_stands{
    background-color: var(--main-white);
    color: var(--main-black);
    font-weight: 500;
    border-radius: 16px;
    border: 3px solid var(--main-green);
    margin: auto;
    padding-left: 3%;
    width: 100%;
    outline: none;
    box-shadow: none;
}

.search-bar_stands::placeholder {
    color: var(--grey-2);
    font-weight: 400;
    padding-left: 3%;
    font-size: 14px;
}

.search-bar_stands:focus {
    outline: none;
    box-shadow: var(--main-black);
    background-color: white;
}

@media (min-width: 0px) and (max-width: 992px) {



    .stands-item-container{
        display: flex;
        width: 100%;
        height: 200px;
    }

        .search-bar__container_stands {
          width: 100%;
        }

        .stands-list__header{
            display: grid;
            grid-auto-columns: 1fr;
            grid-gap: 10px;
            text-align: right;
        }

        .stands-list-container {
            position: relative;
            margin-top: 10px;
            padding: 10px 3px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

}

