.homepage__title--big {
    margin-bottom: 10px;
    margin-top: 15px;
    font-weight: 800;
    font-size: 32px;
    color: var(--main-green);

}
.homepage__title--bigcolor {
    color: black;

}
.homepage__title {
    margin-bottom: 10px;
    margin-top: 15px;
    font-weight: 800;
    font-size: 20px;
    color: var(--main-green);

}
.homepage__content {
    text-align: left;
    background-color: var(--main-white);
    color: white;
    padding: 36px;
    margin-bottom: 6px;
}
.homepage__content-center {
    margin-top: 30%;
}

.homepage__subcontent {
    color:var(--main-green);
    font-weight: 400;
}
.homepage__subcontent--bullet {
    margin-top: 20px;
    width: 40px;
    border-bottom: solid 6px var(--main-green);
}

.homepage__sub-title {
    color: var(--main-green);
    font-weight: 800;
    margin-bottom: 7px;
}


