.update-stand-title {
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 900;
    color: #6c677b;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.update-stand__name-edition-button {
    background-color: transparent;
}

.update-stand-image-preview {
    justify-content: left;
    display: block;
}

.update-stand-image-card-preview {
    margin-left: 10%;
    margin-top: 15px;
    justify-content: left;
    display: block;
}
.update-image__map {
    border: 1px rgb(119, 119, 119) solid;
    border-radius: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.accordion-item {
    background: none !important;
    border: none;
}

 .accordion-header {
    background-color: transparent;
    color: #876cd3 !important;
    border-bottom: 1px solid #876cd3;
}

.accordion-button::after {
    color: #876cd3;
}

.accordion-header h3{
    color: #876cd3;
    font-size: 18px;
}

.accordion__explanation-text{
    color: #838385;
    font-size: 14px;
    margin-bottom: 10px;
}


.accordion-collapse {
    background: none;
    border: none;
}

.accordion-button {
    background: none;
    padding: 10px 15px 5px;
}

.accordion-flush .accordion-item .accordion-button {
    background: none !important;
}
.maintemplates-button {
    font: inherit;
    padding: 4px 24px;
    border-radius: 24px;
    background: var(--main-green);
    color: white;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: ease 0.3s;
}


.maintemplates-button:hover {
    text-decoration: none;
    opacity: 0.5;
    color: white;
}


.maintemplates-button--squared {
    border-radius: 8px;
    padding: 8px 16px;
}